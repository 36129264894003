"use strict";
/**
 * 資料閲覧・お問合せ（子宮内膜再生増殖法ERP）
 *
 * @url https://dev.haramedical.or.jp/inquiry/erp-document-request/
 */
window.addEventListener('DOMContentLoaded', () => {
    setAddressByPostalCode();
    toggleMessageField();
}, false);
const setAddressByPostalCode = () => {
    const postal = document.querySelector('[name="postalcode"]');
    if (postal) {
        // @ts-ignore
        $(`[name="${postal.getAttribute('name')}"]`).jpostal({
            postcode: [`[name="${postal.getAttribute('name')}"]`],
            address: {
                [`[name="pref"]`]: '%3',
                [`[name="address"]`]: '%4%5',
            }
        });
    }
};
const toggleMessageField = () => {
    const $el = document.getElementById('message');
    const $toggle = document.querySelector('[name="content[]"][value="お問合せ"]');
    if ($el && $toggle) {
        $toggle.addEventListener('change', (event) => {
            $el.style.display = $toggle.checked ? '' : 'none';
        }, false);
    }
};
