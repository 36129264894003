"use strict";
/**
 * Navigation
 */
class Navigation {
    constructor() {
        this.line = 0;
        this.isActive = false;
        this.className = 'is-active';
        this.onClick = (event) => {
            if (this.$el) {
                if (!this.isActive) {
                    this.$el.classList.add(this.className);
                }
                else {
                    this.$el.classList.remove(this.className);
                }
                this.isActive = !this.isActive;
            }
        };
        this.onScroll = (event) => {
            const { line, $el } = this;
            const y = window.pageYOffset;
            const className = 'is-fixed';
            if ($el) {
                if (line <= y && !$el.classList.contains(className)) {
                    $el.classList.add(className);
                }
                else if (line > y && $el.classList.contains(className)) {
                    $el.classList.remove(className);
                }
            }
        };
        this.onResize = (event) => this.getLine();
        this.getLine = () => {
            if (this.$body) {
                const rect = this.$body.getBoundingClientRect();
                this.line = rect.top + window.pageYOffset;
            }
        };
        this.$el = document.querySelector('.l-Navigation');
        this.$buttons = document.querySelectorAll('.c-Toggler');
        this.$body = document.querySelector('.l-Body');
        if (this.$el && this.$buttons) {
            for (let i = 0; i < this.$buttons.length; i++)
                this.$buttons[i].addEventListener('click', this.onClick, false);
        }
        /**
        * Scroll settings
        */
        this.getLine();
        this.onScroll();
        this.toggleChildren();
        window.addEventListener('scroll', this.onScroll, false);
        window.addEventListener('resize', this.onResize, false);
    }
    toggleChildren() {
        const className = "js-open";
        const items = document.querySelectorAll(".l-Navigation_Item");
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            ((item) => {
                const toggler = item.querySelector(".l-Navigation_Item--toggler");
                const children = item.querySelector(".l-Navigation_Children");
                if (toggler && children) {
                    toggler.addEventListener("click", (event) => {
                        event.preventDefault();
                        if (!item.classList.contains(className)) {
                            children.style.maxHeight = `${children.scrollHeight}px`;
                            item.classList.add(className);
                        }
                        else {
                            children.style.maxHeight = "0px";
                            item.classList.remove(className);
                        }
                    });
                }
            })(item);
        }
    }
}
window.addEventListener('DOMContentLoaded', () => {
    new Navigation;
}, false);
